<template>
  <div class="box">
    <div class="bannerBox">
      <img class="banner" src="/subject/newReport/wap/banner.jpg" alt="">
      <img @click="goBack" class="logo" src="/subject/newReport/wap/logo.png" alt="">
    </div>
    <div class="content">
      <div class="part part1">
        <img class="title" src="/subject/newReport/wap/title1.png" alt="">
        <div class="newVideoBox">
          <video class="craig" v-if="!ckplayerObject.live" :src="isVideo" controls="controls" autoplay="autoplay" muted></video>
          <video v-if="ckplayerObject.live" id="videoPlayer" class="video-js" muted></video>
          <div class="swiperVideo" v-if="!ckplayerObject.live">
            <swiper ref="videoSwiper" :options="swiperVideoOption"> 
              <div class="swiper-slide" v-for="(item,i) in teacherSwiper" :key="i">
                <img @click="goVideo(i)" :src="item" />
              </div>
              <!-- 分页器 -->
              <!-- <div class="swiper-pagination" slot="pagination"></div> -->
            </swiper>
          </div>
        </div>
        <img class="other" src="/subject/newReport/wap/content1.png" alt="">
        <p>
          <img @click="live800" src="/subject/newReport/wap/live800.png" alt="">
          <img @click="Login" src="/subject/newReport/wap/login.png" alt="">
        </p>
        <img class="live800text" src="/subject/newReport/wap/live800text.png" alt="">
      </div>
      <div class="part part2">
        <img class="title" src="/subject/newReport/wap/title2.png" alt="">
        <img class="other" src="/subject/newReport/wap/content2.png" alt="">
        <p>
          <img @click="live800" src="/subject/newReport/wap/live800.png" alt="">
          <img @click="Login" src="/subject/newReport/wap/login.png" alt="">
        </p>
        <img class="live800text" src="/subject/newReport/wap/live800text.png" alt="">
      </div>
      <div class="part part3">
        <img class="title" src="/subject/newReport/wap/title3.png" alt="">
        <img class="other" src="/subject/newReport/wap/content3.png" alt="">
        <p>
          <img @click="live800" src="/subject/newReport/wap/live800.png" alt="">
          <img @click="Login" src="/subject/newReport/wap/login.png" alt="">
        </p>
        <img class="live800text" src="/subject/newReport/wap/live800text.png" alt="">
      </div>
      <div class="part part4">
        <img class="title" src="/subject/newReport/wap/title4.png" alt="">
        <img class="other" src="/subject/newReport/wap/content4.png" alt="">
        <img @click="downloadReport" class="download" src="/subject/newReport/wap/download.png" alt="">
        <p>
          <img @click="live800" src="/subject/newReport/wap/live800.png" alt="">
          <img @click="Login" src="/subject/newReport/wap/login.png" alt="">
        </p>
        <div class="swiperBox">
          <swiper ref="mySwiper" :options="swiperOption"> 
            <div class="swiper-slide">
              <img src="/subject/newReport/1.png" />
            </div>
            <div class="swiper-slide">
              <img src="/subject/newReport/2.png" />
            </div>
            <div class="swiper-slide">
              <img src="/subject/newReport/3.png" />
            </div>
            <!-- 分页器 -->
            <!-- <div class="swiper-pagination" slot="pagination"></div> -->
          </swiper>
        </div>
        <img class="live800text" src="/subject/newReport/wap/live800text.png" alt="">
      </div>
      <div class="part part5">
        <img class="title" src="/subject/newReport/wap/title5.png" alt="">
        <img class="other" src="/subject/newReport/wap/content5.png" alt="">
      </div>
    </div>
    <contactus v-if="!isApp"></contactus>
  </div>
</template>

<script>
import Contactus from '../../Contactus.vue';
import {livingRoomNow} from '../../../../api/info'
import videojs from 'video.js'
export default {
  name:'newReportWap',
  components:{
    Contactus,
  },  
  data(){
    return{
      // swiper 参数
      swiperOption: {
        slidesPerView: 3,
        // swiper图片之间的间隔
        spaceBetween: 130,
        centeredSlides: true,
        grabCursor:true,
        loop: true,
        preventClicks : true,
          //自动播放
        autoplay: {
          delay: 4000, //延迟播放下一张图片
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperVideoOption: {
        slidesPerView: 3,
        // swiper图片之间的间隔
        spaceBetween: 50,
        centeredSlides: true,
        grabCursor:true,
        loop: true,
        preventClicks : true,
          //自动播放
        autoplay: {
          delay: 4000, //延迟播放下一张图片
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      isVideo:'https://www.rlcvipltd.net/static/video/v-0320.mp4',
      screenWidth:null,
      scroll: Number,
      isApp: false,
      timer:null,
      myPlyer:null,
      options:null,
      teacherSwiper:[
        '/subject/newReport/wap/1.png',
        '/subject/newReport/wap/2.png',
        '/subject/newReport/wap/3.png',
        '/subject/newReport/wap/4.png',
        '/subject/newReport/wap/5.png',
      ]
    }
  },
  computed:{
    ckplayerObject() {
      return this.$store.state.home.ckplayerObject
    },
    teacherPlayBack(){
      return this.$store.state.home.teacherPlayBack
    }
  },
  created(){
    // 传给App.vue,不展示tabbar
    this.$emit('controlShow',true);
    if(this.$route.query.isApp){
      this.isApp = true;
    }
    this.init();
    this.timer = window.setInterval(() => {
      setTimeout(this.init(), 0)
    }, 1000*10)
    this.playBack();
  },
  beforeDestroy() {
    //在 beforeDestroy生命周期函数中销毁定时器
    clearInterval(this.timer);
    this.timer = null;
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll);
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })()
    };
    // setTimeout(()=>{
    //   this.$nextTick(() => {
    //     this.initVideo(this.isVideo);
    //   })   
    // },500) 
  },
  watch: {
    screenWidth: function (n) {
      if (n > 500) {
        this.$router.push({
          name: "newReportPC",
        });
      }
    },
  },
  methods:{
    goBack(){
      window.location.href = window.location.origin;
    },
    live800(){
      window.open(this.$parent.live800UrlUc);
    },
    Login(){
      window.open("https://www.rlcvipltd.net/uc/login");
    },
    // 获取是否直播以及直播流
    init(){
      livingRoomNow({platid:1}).then(res=>{
        if(res.Status == 0 && res.content.length>0){
          this.$store.commit('home/set_ckplayerObject',{video: res.content[0].param.pcurl ,live: true})
          this.isVideo = res.content[0].param.pcurl;
          setTimeout(()=>{
            this.initVideo(this.isVideo);
          },500)
        }else{
          this.$store.commit('home/set_ckplayerObject',{video: 'https://www.rlcvipltd.net/static/video/v-0320.mp4' , live: false})
          this.isVideo = 'https://www.rlcvipltd.net/static/video/v-0320.mp4'; 
          // this.myPlyer.dispose();
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    // 初始化videojs内容
    initVideo(nowPlayVideoUrl) {
      // 这些options属性也可直接设置在video标签上，见 muted
      this.options = {
        autoplay: true, // 设置自动播放
        controls: true, // 显示播放的控件
        sources: [
        // 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
          {
          src: nowPlayVideoUrl,
          type: "application/x-mpegURL" // 告诉videojs,这是一个hls流
          }
        ]
      };
      // videojs的第一个参数表示的是，文档中video的id
      this.myPlyer = videojs("videoPlayer", this.options, function onPlayerReady() {
      console.log("onPlayerReady 中的this指的是：", this); // 这里的this是指Player,是由Videojs创建出来的实例
      console.log(this.myPlyer === this); // 这里返回的是true
      });
    },
    // 控制顶部导航栏的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if(this.scroll > 90 ){
        this.navBarFixed = true;
      }else{
        this.navBarFixed = false;
      }
    },
    // 下载报告书
    downloadReport(){
      window.open('https://oss.0790jiaxiao.com/Report/SpingSummerInvestment2023032801.pdf');
    },
    // 获取老师直播回放
    async playBack(){
      const data = {
        platid:1,
        sort:'ut,DESC',
        type:0,
        page:0,
        size:10,
      }
      await this.$store.dispatch('home/getPlayBack', data);
    },
    // 跳转视频回放
    goVideo(attr){
      // console.log(this.teacherPlayBack);
      switch (attr) {
        case 0:
          window.open(this.teacherPlayBack[4].fpath)
          break
        case 1:
          window.open(this.teacherPlayBack[3].fpath)
          break
        case 2:
          window.open(this.teacherPlayBack[2].fpath)
          break
        case 3:
          window.open(this.teacherPlayBack[1].fpath)
          break
        case 4:
          window.open(this.teacherPlayBack[0].fpath)
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .box{
    width: 100%;
    max-width: 500px;
    .bannerBox{
      width: 100%;
      position: relative;
      .banner{
        width: 100%;
        display: block;
      }
      .logo{
        width: 125px;
        position: absolute;
        top: 16%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .content{
      width: 100%;
      height: 3060px;
      background: url('/subject/newReport/wap/background.png') no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding-top: 16px;
      .part{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 16px;
        position: relative;
        .title{
          width: 100%;
        }
        .other{
          width: 344px;
        }
        p{
          width: 100%;
          position: absolute;
          bottom: 2%;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 127px;
            margin: 0 10px;
          }
        }
        .live800text{
          width: 120px;
          position: absolute;
          bottom: 1%;
          left: 15%;
        }
      }
      .part1{
        margin-top: 0;
        p{
          bottom: 5%;
        }
        .newVideoBox{
          width: auto;
          height: 165px;
          position: relative;
          .craig{
            width: 344px;
            height: 165px;
          }
          .swiperVideo{
            width: 344px;
            height: 70px;
            position: absolute;
            bottom: -20%;
            left: 50%;
            transform: translate(-50%,-50%);
            .swiper-container{
              width: 100%;
              height: 100%;
              .swiper-wrapper{
                .swiper-slide{
                  img{
                    width: 120px;
                    object-fit: cover;
                  }
                  img:hover{
                    border: 1px solid #fff;
                    border-radius: 5px;
                  }
                }
              }
            }
          }
        }
      }
      .part2{
        margin-top: 0;
        p{
          bottom: 2%;
        }
        .live800text{
          bottom: 0.2%;
        }
      }
      .part3{
        margin-top: 0;
        p{
          bottom: 5%;
        }
        .live800text{
          bottom: 1.2%;
        }
      }
      .part4{
        margin-top: 0;
        p{
          bottom: 3%;
        }
        .download{
          width: 175px;
          position: absolute;
          top: 28%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
        .swiperBox{
          width: 90%;
          position: absolute;
          top: 53%;
          left: 50%;
          transform: translate(-50%,-50%);
          .swiper-container{
            width: 100%;
            height: 260px;
            .swiper-wrapper{
              .swiper-slide{
                img{
                  width: 140px;
                  object-fit: cover;
                }
                /* Center slide text vertically */
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;
                transition: 300ms;
                transform: scale(1);
              }
              .swiper-slide-active,.swiper-slide-duplicate-active{
                transform: scale(1.2);
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 380px) {
    .box .content .part4 .swiperBox[data-v-140245c8]{
      width: 83%;
    }
    .box .content .part4 .download[data-v-140245c8]{
      top: 29%;
    }
  }
  /deep/ .videoPlayer-dimensions{
    width: 344px;
    height: 165px;
  }
</style>